'use strict';

angular.module('kljDigitalLibraryApp')
    .controller('ConfirmationCtrl', ["$scope", "$uibModalInstance", "confirmationMessage",
        function($scope, $uibModalInstance, confirmationMessage) {
            $scope.confirmationMessage = confirmationMessage;

            $scope.close = function() {
                console.log("no");
                $uibModalInstance.close();
            }

            $scope.deleteNews = function(flag) {
                console.log("flag-->", flag);
                $uibModalInstance.close("delete");
            }

        }
    ]);